import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const NavbarDropdownWrapper = styled(Dropdown)`
  position: relative;
  z-index: 1200 !important;
`

const NavbarDropdownToggle = styled(DropdownToggle)`
  background-color: transparent !important;
  color: ${(props) => props.theme.colorBlack}!important;
  font-size: 16px;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.2s ease;
  ${(props) =>
    props.sticky &&
    `
    font-size: 15px;
  `}
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
    ${(props) =>
      props.sticky &&
      `
      font-size: 13px;
    `}
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 13px;
    ${(props) =>
      props.sticky &&
      `
      font-size: 12px;
    `}
  }
  @media (min-width: 768px) {
    ${(props) =>
      props.sticky &&
      `
      // padding-top: 0.25rem;
      // padding-bottom: 0.25rem;
    `}
  }
  &:hover {
    color: ${(props) => props.theme.colorGreenLight}!important;
  }
`

const NavbarDropdownToggleLink = styled(Link)`
  color: ${(props) => props.theme.colorBlack}!important;
  text-decoration: none !important;
  @media (max-width: 991px) {
    color: ${(props) => props.theme.colorWhite}!important;
    font-size: 22px;
    font-weight: 700;
    padding-top: 8px;
    padding-bottom: 8px;
    /* border-bottom: 1px solid ${(props) => props.theme.colorWhite}; */
    ${(props) =>
      props.sticky &&
      `
      font-size: 18px;
    `}
  }
  &[aria-current='page'] {
    @media (min-width: 992px) {
      color: ${(props) => props.theme.colorGreenLight}!important;
    }
    @media (max-width: 991px) {
      color: ${(props) => props.theme.colorWhite}!important;
      font-weight: 700;
    }
  }
`

const NavbarDropdownMenu = styled(DropdownMenu)`
  top: calc(100% - 1px);
  float: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  border-radius: 0;
  transition: all 0.2s ease;
  z-index: 2000 !important;
  @media (max-width: 991px) {
    position: relative !important;
    display: block !important;
    background-color: ${(props) => props.theme.colorGreenLight};
    border: none;
  }
  &:before {
    @media (max-width: 991px) {
      content: '';
      position: absolute;
      top: 20px;
      left: 20px;
      bottom: 20px;
      width: 1px;
      bottom: 20px;
      background-color: ${(props) => props.theme.colorWhite};
    }
  }
`

const NavbarDropdownItem = styled(DropdownItem)`
  padding-left: 45px;
  padding-right: 45px;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colorGreenLight};
    a {
      color: ${(props) => props.theme.colorWhite}!important;
      &:before {
        background-color: ${(props) => props.theme.colorWhite};
      }
    }
  }
`

const NavbarDropdownLink = styled(NavbarDropdownToggleLink)`
  position: relative;
  display: inline-block;
  width: 100%;
  @media (max-width: 991px) {
    font-weight: 300;
    padding-top: 0;
    padding-bottom: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    width: 5px;
    height: 5px;
    background-color: ${(props) => props.theme.colorGreenLight};
    border-radius: 50%;
    transform: translateY(-50%);
    @media (max-width: 991px) {
      content: none;
    }
  }
`

const NavbarDropdown = (props) => {
  const { sticky, toggleName, toggleLink, items } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(!dropdownOpen)

  const toggleOver = () => {
    if (document.body.clientWidth >= 992) {
      setDropdownOpen(true)
    }
  }

  const toggleOut = () => {
    if (document.body.clientWidth >= 992) {
      setDropdownOpen(false)
    }
  }

  return (
    <NavbarDropdownWrapper
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseEnter={toggleOver}
      onMouseLeave={toggleOut}
      nav
      inNavbar
    >
      <NavbarDropdownToggle sticky={sticky} nav>
        <NavbarDropdownToggleLink to={toggleLink} title={toggleName}>
          {toggleName}
        </NavbarDropdownToggleLink>
      </NavbarDropdownToggle>
      <NavbarDropdownMenu sticky={sticky}>
        {items.map((item, idx) => {
          const { name, link } = item
          return (
            <NavbarDropdownItem key={idx} onClick={toggle}>
              <NavbarDropdownLink to={link} title={name}>
                {name}
              </NavbarDropdownLink>
            </NavbarDropdownItem>
          )
        })}
      </NavbarDropdownMenu>
    </NavbarDropdownWrapper>
  )
}

export default NavbarDropdown
