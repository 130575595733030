import React from 'react'
import { Link, useStaticQuery, graphql, withPrefix } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { NonceImg } from './img'

const FooterWrapper = styled.div`
  color: #5f5f5f;
  font-size: 13px;
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: 18px solid #3b8256;
`

const FooterTopWrapper = styled(Row)`
  margin-bottom: 30px;
`

const FooterColLogo = styled(Col)`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    position: relative;
    margin-bottom: 40px;
  }
  &:after {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: -20px;
    display: block;
    height: 1px;
    background-color: #f4f4f4;
    @media (max-width: 767px) {
      content: '';
    }
  }
  p {
    margin-left: 10px;
    margin-bottom: 0;
  }
`

const FooterLogoLink = styled(Link)`
  display: block;
  width: 100px;
  height: 25px;
`

const FooterColList = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const FooterList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    &:last-of-type {
      padding-right: 0;
      @media (max-width: 767px) {
        padding-top: 15px;
      }
    }
    a {
      color: #5f5f5f;
      text-decoration: none;
      transition: all 0.2s ease;
      &:hover {
        color: #00965e;
      }
    }
  }
`

const FooterColDesc = styled(Col)`
  p {
    padding-top: 15px;
    font-size: 10px;
    margin-bottom: 0;

    a {
      color: ${(props) => props.theme.colorGreenLight};
    }
  }
`

// const FooterColDesigned = styled(Col)`
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// `

// const FooterDesignedLink = styled.a`
//   display: inline-block;
//   width: 127px;
//   height: 22px;
// `

const Footer = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      footerLogo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      footerDesigned: file(relativePath: { eq: "designed.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <Container>
        <FooterTopWrapper>
          <FooterColLogo md={6}>
            <FooterLogoLink to="/">
              <NonceImg alt="Bank BNP Paribas" fluid={data.footerLogo.childImageSharp.fluid} />
            </FooterLogoLink>
            <p>Bank zmieniającego się świata</p>
          </FooterColLogo>
          <FooterColList md={6}>
            <FooterList>
              <li>
                <a href="https://www.bnpparibas.pl/repozytorium/polityka-prywatnosci">
                  Polityka prywatności
                </a>
              </li>
              <li>
                <a href="https://www.bnpparibas.pl/repozytorium/nota-prawna">
                  Nota prawna
                </a>
              </li>
              <li>© {new Date().getFullYear()} BNP Paribas Bank Polska SA</li>
            </FooterList>
          </FooterColList>
        </FooterTopWrapper>
        <Row>
          <FooterColDesc xs={12}>
            {location &&
              location.pathname.match(
                withPrefix('/ekwipunek-samodzielniaka/?')
              ) && (
                <>
                  <p>
                    Niniejszy materiał ma charakter wyłącznie reklamowy i
                    informacyjny oraz nie stanowi oferty w rozumieniu kodeksu
                    cywilnego.
                  </p>
                  <p>
                    <sup>1</sup> Szczegółowe informacje dotyczące otwarcia i
                    prowadzenia przez Bank Konta Samodzielniaka oraz wydawanej
                    do tego konta Karty do Dorosłości znajdują się w sekcji
                    Dokumenty na stronie
                    www.bnpparibas.pl/klienci-indywidualni/konta/konto-samodzielniaka
                    (Taryfa Prowizji i Opłat Rachunków bankowych, Karty oraz
                    Elektronicznych Kanałów Dostępu dla Klientów detalicznych;
                    Ogólne Warunki Umowy Rachunków bankowych, Karty oraz
                    Elektronicznych Kanałów Dostępu oraz Komunikat do Umowy
                    Rachunków bankowych, Karty oraz Elektronicznych Kanałów
                    Dostępu).
                  </p>
                  <p>
                    <sup>2</sup> Szczegółowe informacje dotyczące dziennych
                    limitów Karty Samodzielniaka i Mikrokarty Samodzielniaka
                    określa Komunikat do Umowy Rachunków bankowych, Karty oraz
                    Elektronicznych Kanałów Dostępu. Dziecko może wykonywać
                    transakcje płatnicze przy użyciu kart wydanych do Konta
                    Karty Samodzielniaka w ramach limitów ustalonych przez
                    posiadacza tego konta (rodzica lub przedstawiciela
                    ustawowego dziecka). Limity mogą być wyłącznie zmieniane
                    przez przedstawiciela ustawowego dziecka.
                  </p>
                  <p>
                    <sup>3</sup> Dla dziecka w wieku 13-18 lat dostęp do systemu
                    bankowości internetowej GOonline i aplikacji mobilnej
                    GOmobile wymaga zgody przedstawiciela ustawowego. Zarówno
                    przedstawiciel ustawowy, jak i dziecko w wieku 13-18 lat
                    mogą dysponować środkami zgromadzonymi na Koncie
                    Samodzielniaka w granicach zwykłego zarządu. Szczegółowe
                    informacje znajdują się w Ogólnych Warunkach Umowy Rachunków
                    bankowych, Karty oraz Elektronicznych Kanałów Dostępu.
                  </p>
                  <p>
                    <sup>4</sup> Rodzic lub opiekun prawny dziecka rozumiany
                    jako przedstawiciel ustawowy dziecka, mający pełną zdolność
                    do czynności prawnych.
                  </p>
                  <p>
                    <sup>5</sup> Karta do Dorosłości to karta debetowa wydawana
                    przez Bank do Konta Samodzielniaka dla osoby małoletniej,
                    która ukończyła 13. rok życia i jest posiadaczem Konta
                    Samodzielniaka.
                  </p>
                  <p>
                    <sup>6</sup> Szczegółowe informacje dotyczące dziennych
                    limitów Karty do Dorosłości określa Komunikat do Umowy
                    Rachunków bankowych, Karty oraz Elektronicznych Kanałów
                    Dostępu.
                  </p>
                </>
              )}

            <p>
              BNP Paribas Bank Polska Spółka Akcyjna z siedzibą w Warszawie przy
              ul. Kasprzaka 2, 01-211 Warszawa, zarejestrowany w rejestrze
              przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla
              m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego
              Rejestru Sądowego pod nr. KRS 0000011571, posiadający NIP
              526-10-08-546 oraz kapitał zakładowy w wysokości 147 676 946 zł w
              całości wpłacony.
            </p>
          </FooterColDesc>
          {/* <FooterColDesigned md={2}>
            <FooterDesignedLink
              href="http://www.e-point.pl/"
              target="_blank"
              rel="nofollow noopener"
            >
              <NonceImg fluid={data.footerDesigned.childImageSharp.fluid} />
            </FooterDesignedLink>
          </FooterColDesigned> */}
        </Row>
      </Container>
    </FooterWrapper>
  )
}

export default Footer
