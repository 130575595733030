import React, { useState } from 'react'
import { Link, useStaticQuery, graphql, withPrefix } from 'gatsby'
import styled from 'styled-components'
import Sticky from 'react-stickynode'
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import NavbarDropdown from './elements/navbar-dropdown'
import { NonceImg } from './img'

import hamburgerOpen from '../images/hamburger_open.svg'
import hamburgerClose from '../images/hamburger_close.svg'

const NavbarHeaderSticky = styled(Sticky)``

const NavbarHeaderWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colorWhite};
  transition: all 0.2s ease;
  ${(props) =>
    props.sticky &&
    `
    border-bottom: 1px solid ${props.theme.colorGreenLight};
  `}
  @media (max-width: 991px) {
    /* position: relative; */
  }
`

const NavbarHeaderNavbar = styled(Navbar)`
  padding-bottom: 0;
  transition: all 0.2s ease;
  ${(props) =>
    props.sticky &&
    `
    padding-top: 0.125rem;
    // padding-bottom: 0.1rem;
  `}
`

const NavbarHeaderContainer = styled(Container)`
  @media (min-width: 992px) {
    display: block !important;
  }
`

const NavbarHeaderLogo = styled(Link)`
  color: ${(props) => props.theme.colorBlack}!important;
  @media (min-width: 992px) {
    width: 100%;
  }
  @media (min-width: 768px) {
    padding-bottom: 0.8rem;
    transition: all 0.2s ease;
    ${(props) =>
    props.sticky &&
    `
      padding-bottom: 0;
    `}
  }
  .topLogo {
    display: inline-block;
    width: 250px;
    transition: all 0.2s ease;
    ${(props) =>
    props.sticky &&
    `
      width: 162px;
    `}
    @media (max-width: 575px) {
      width: 170px;
    }
  }
  p {
    position: relative;
    top: -18px;
    display: inline-block;
    color: ${(props) => props.theme.colorBlack}!important;
    font-size: 18px;
    padding-left: 35px;
    margin-bottom: 0;
    transition: all 0.2s ease;
    ${(props) =>
    props.sticky &&
    `
      top: -11px;
      font-size: 16px;
      padding-left: 18px;
    `}
    @media (max-width: 991px) {
      display: none;
    }
  }
`

const NavbarHeaderToggler = styled(NavbarToggler)`
  background-image: ${(props) =>
    !props.open
      ? `url(${hamburgerOpen})`
      : `
    url(${hamburgerClose})
  `};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: all 0.2s ease;
  ${(props) =>
    props.sticky &&
    `
    font-size: 0.95rem;
  `}
  @media (max-width: 575px) {
    font-size: 1rem;
  }
  &:hover,
  &:focus {
    outline: none;
  }
`

const NavbarHeaderCollapse = styled(Collapse)`
  @media (max-width: 991px) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.colorGreenLight};
    z-index: 1000;
  }
`

const NavbarHeaderNav = styled(Nav)`
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 140px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 720px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 540px;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const NavbarHeaderNavItem = styled(NavItem)`
  &:first-of-type {
    a {
      @media (min-width: 768px) {
        padding-left: 0 !important;
      }
    }
  }
  &:last-of-type {
    a {
      @media (max-width: 991px) {
        border-bottom: none;
      }
    }
  }
`

const NavbarHeaderLink = styled(NavLink)`
  color: ${(props) => props.theme.colorBlack};
  font-size: 16px;
  transition: all 0.2s ease;

  @media (min-width: 992px) {
    padding-bottom: 1rem;
    ${(props) =>
    props.sticky &&
    `
      font-size: 15px;
    `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 14px;
    ${(props) =>
    props.sticky &&
    `
    font-size: 13px;
  `}
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 13px;
    ${(props) =>
    props.sticky &&
    `
      // font-size: 12px;
    `}
  }
  @media (min-width: 768px) {
    ${(props) =>
    props.sticky &&
    `
      // padding-top: 0.25rem;
      // padding-bottom: 0.25rem;
    `}
  }
  @media (max-width: 991px) {
    color: ${(props) => props.theme.colorWhite}!important;
    font-size: 22px;
    font-weight: 700;
    padding-top: 8px;
    padding-bottom: 8px;
    /* border-bottom: 1px solid ${(props) => props.theme.colorWhite}; */
    ${(props) =>
    props.sticky &&
    `
      // font-size: 18px;
    `}
  }
  &:hover {
    color: ${(props) => props.theme.colorGreenLight};
  }
  &[aria-current='page'] {
    color: ${(props) => props.theme.colorGreenLight};
    @media (max-width: 991px) {
      color: ${(props) => props.theme.colorWhite};
      font-weight: 700;
    }
  }
`

const NavbarHeader = (props) => {
  const data = useStaticQuery(graphql`
    query {
      navbarHeaderLogo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const NavbarOverlay = styled.div`
    display: none;
    height: 100vh;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    @media (max-width: 991px) {
      display: ${(props) => (props.open ? `block` : `none`)};
    }
  `

  const [isOpen, setIsOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const handleStickyStateChange = (status) => {
    if (status.status === NavbarHeaderSticky.STATUS_FIXED) {
      setIsSticky(true)
    }
    if (status.status === NavbarHeaderSticky.STATUS_ORIGINAL) {
      setIsSticky(false)
    }
  }
  console.log(process.env)

  return (
    <NavbarHeaderSticky
      enabled={true}
      onStateChange={handleStickyStateChange}
      innerZ={'9999'}
    >
      <NavbarHeaderWrapper sticky={isSticky}>
        <NavbarOverlay onClick={toggle} open={isOpen} />
        <NavbarHeaderNavbar expand="lg" sticky={isSticky}>
          <NavbarHeaderContainer>
            <NavbarHeaderLogo to="/" className="navbar-brand" sticky={isSticky}>
              <NonceImg
                alt="Bank BNP Paribas"
                className="topLogo"
                fluid={data.navbarHeaderLogo.childImageSharp.fluid}
              />
              <p>Bank zmieniającego się świata</p>
            </NavbarHeaderLogo>
            <NavbarHeaderToggler
              onClick={toggle}
              open={isOpen}
              sticky={isSticky}
            />
            <NavbarHeaderCollapse isOpen={isOpen} navbar>
              <NavbarHeaderNav navbar>
                <NavbarHeaderNavItem>
                  <NavbarHeaderLink
                    as={Link}
                    to="/"
                    className="nav-link"
                    sticky={isSticky}
                  >
                    Nasza Misja
                  </NavbarHeaderLink>
                </NavbarHeaderNavItem>
                <NavbarDropdown
                  sticky={isSticky}
                  toggleName="Strefa nauczyciela"
                  toggleLink="/strefa-nauczyciela"
                  items={[
                    {
                      name: 'Program Misja Kieszonkowe',
                      link: '/strefa-nauczyciela',
                    },
                    {
                      name: 'Dołącz do programu',
                      link: '/strefa-nauczyciela/konkurs/zgloszenie-klasy',
                    },
                    {
                      name: 'Konkurs Misja Kieszonkowe',
                      link: '/strefa-nauczyciela/konkurs/',
                    },
                  ]}
                />
                <NavbarHeaderNavItem>
                  <NavbarHeaderLink
                    as={Link}
                    to="/strefa-dziecka"
                    className="nav-link"
                    sticky={isSticky}
                  >
                    Strefa dziecka
                  </NavbarHeaderLink>
                </NavbarHeaderNavItem>
                <NavbarHeaderNavItem>
                  <NavbarHeaderLink
                    as={Link}
                    to="/strefa-rodzica"
                    className="nav-link"
                    sticky={isSticky}
                  >
                    Strefa rodzica
                  </NavbarHeaderLink>
                </NavbarHeaderNavItem>
                <NavbarHeaderNavItem>
                  <NavbarHeaderLink
                    as={Link}
                    to="/ekwipunek-samodzielniaka"
                    className="nav-link"
                    sticky={isSticky}
                  >
                    Konto Samodzielniaka
                  </NavbarHeaderLink>
                </NavbarHeaderNavItem>
              </NavbarHeaderNav>
            </NavbarHeaderCollapse>
          </NavbarHeaderContainer>
        </NavbarHeaderNavbar>
      </NavbarHeaderWrapper>
    </NavbarHeaderSticky>
  )
}

export default NavbarHeader
